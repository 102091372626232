<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card
      v-if="courseData && !isComplete"
    >
      <b-card-title>{{ courseData.c_name }}</b-card-title>
      <b-card-sub-title
        v-html="courseData.c_descr"
        class="course-subtitle mb-0"
      />
    </b-card>
    <form-wizard
      v-if="courseData && !isComplete"
      ref="refFormWizard"
      color="#1D71B8"
      :startIndex="startIndex"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="form-wizard steps-transparent"
    >
      <tab-content
        v-for="(lesson, key) in courseData.lessons"
        :key="key"
        :title="lesson.les_name"
        :icon="lesson.les_icon"
        class="w-100 relative"
      >
        <b-overlay
          :show="loadingLesson"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <b-card
          v-if="lessonData.length"
          :title="lesson.les_name"
          :sub-title="lesson.les_descr"
          class="w-100"
        >
          <div
            v-if="lessonData[key]"
          >
            <div>
              <div
                v-if="lessonData[key].les_type === '1'"
              >
                <div
                  v-if="lessonData[key].les_video"
                >
                  <iframe
                    v-if="lessonData[key].les_video.indexOf('youtu.be') > -1"
                    height="400"
                    width="100%"
                    :src="`https://www.youtube.com/embed/${lessonData[key].les_video.split('/')[lessonData[key].les_video.split('/').length - 1]}`"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                  <kinescope-player
                    v-else
                    :video-id="lessonData[key].les_video"
                    @ready="handleReady"
                    @play="handlePlay"
                    class="course-player"
                    height="400"
                  ></kinescope-player>
                </div>
                <div
                  v-if="lessonData[key].les_content && lessonData[key].les_content[0]"
                  v-html="lessonData[key].les_content[0].html"
                  class="mt-2"
                />
              </div>
              <div
                v-if="lessonData[key].les_type === '2'"
              >
                <b-carousel
                  v-if="lessonData[key].les_content && lessonData[key].les_content.length"
                  id="carousel-fade"
                  fade
                  indicators
                  :interval="3000"
                >
                  <b-carousel-slide
                    v-for="(slide, key) in lessonData[key].les_content"
                    :key="key"
                  >
                    <template #img>
                      <div>
                        <img
                          :src="server + slide.image"
                          class="course-slide-img"
                        >
                      </div>
                      <div class="mt-2" v-html="slide.html" />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
              <div
                v-if="lessonData[key].les_type === '3'"
              >
                <div
                  v-for="(content, key) in lessonData[key].les_content"
                  :key="key"
                  class="mb-2"
                >
                  <h5 class="mb-1">{{ content.question }}</h5>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio
                      v-for="(ask, k) in content.ask"
                      :key="k"
                      :aria-describedby="ariaDescribedby"
                      :name="`ask-${key}`"
                      class="mb-50 ask-radio"
                      @change="changeAsk(key, k)"
                    >
                      <span class="text-muted">{{ ask.askText }}</span>
                    </b-form-radio>
                  </b-form-group>
                  <hr v-if="lessonData[key] && key !== lessonData[key].les_content.length - 1">
                </div>
              </div>
              <div
                v-if="lessonData[key].fileLink || lessonData[key].fileLink2 || lessonData[key].fileLink3 || lessonData[key].fileLink4"
                class="mt-2 mb-2"
              >
                <div
                  v-if="lessonData[key].fileLink"
                >
                  <span class="mr-50">{{ lessonData[key].fileName }}</span>
                  <b-link
                    :href="`${server}${lessonData[key].fileLink}`"
                    target="blank"
                  >Скачать</b-link>
                </div>
                <div
                  v-if="lessonData[key].fileLink2"
                >
                  <span class="mr-50">{{ lessonData[key].fileName2 }}</span>
                  <b-link
                    :href="`${server}${lessonData[key].fileLink2}`"
                    target="blank"
                  >Скачать</b-link>
                </div>
                <div
                  v-if="lessonData[key].fileLink3"
                >
                  <span class="mr-50">{{ lessonData[key].fileName3 }}</span>
                  <b-link
                    :href="`${server}${lessonData[key].fileLink3}`"
                    target="blank"
                  >Скачать</b-link>
                </div>
                <div
                  v-if="lessonData[key].fileLink4"
                >
                  <span class="mr-50">{{ lessonData[key].fileName4 }}</span>
                  <b-link
                    :href="`${server}${lessonData[key].fileLink4}`"
                    target="blank"
                  >Скачать</b-link>
                </div>
              </div>
              <div
                v-if="lessonData[key].les_question && lessonData[key].les_question.length"
              >
                <div
                  v-for="(content, qkey) in lessonData[key].les_question"
                  :key="qkey"
                  class="mt-2"
                >
                  <h5 class="mb-1">{{ content.question }}</h5>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio
                      v-for="(ask, k) in content.ask"
                      :key="k"
                      :aria-describedby="ariaDescribedby"
                      :name="`ask-${qkey}`"
                      class="mb-50 ask-radio"
                      @change="changeXAsk(key, qkey, k)"
                    >
                      <span class="text-muted">{{ ask.askText }}</span>
                    </b-form-radio>
                  </b-form-group>
                  <hr v-if="lessonData[key] && qkey !== lessonData[key].les_question.length - 1">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </tab-content>
      <template slot="footer" slot-scope="props">
       <div class="wizard-footer-left">
          <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Предыдущий урок</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="nextTab(props)" class="wizard-footer-right" :style="props.fillButtonStyle">Следующий урок</wizard-button>
          <wizard-button v-else @click.native="handleComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Завершить' : 'Следующий урок'}}</wizard-button>
        </div>
      </template>
    </form-wizard>
    <div v-if="isComplete">
      <b-card>
        <div v-html="finishData" />
      </b-card>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BFormGroup,
  BFormRadio,
  BLink,
  BOverlay,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import {
  FormWizard,
  TabContent,
  WizardButton,
} from 'vue-form-wizard'
import { KinescopePlayer } from '@kinescope/vue-kinescope-player'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    BCard,
    BCarousel,
    BCarouselSlide,
    BFormGroup,
    BFormRadio,
    BLink,
    BOverlay,
    BCardTitle,
    BCardSubTitle,
    KinescopePlayer,
  },
  setup(props, { root }) {
    const server = process.env.VUE_APP_SERVER
    const isComplete = ref(false)
    const loadingLesson = ref(false)
    const lessonData = ref([])
    const questionsData = ref(null)
    const xquestionsData = ref([])

    const fetchLesson = async (lesson, key, last = 0) => {
      loadingLesson.value = true
      return store.dispatch('app/fetchData2', {
        api: 'https://api.prohealthfactor.ru/api/v1/json/lkb/info/course/item/learnLesson',
        apiId: lesson.id,
      }).then(res => {
        const { data } = res.data

        if (data) {
          lessonData.value = [
            ...lessonData.value,
            data,
          ]
        }

        if (startIndex.value > lessonData.value[key].les_content.length - 1) {
          last = lessonData.value[key].les_content.length - 1
        }

        if (lessonData.value[key].les_type === '3') {
          // eslint-disable-next-line
          questionsData.value = lessonData.value[key].les_content.map(() => {
            return {}
          })
        }

        if (lessonData.value[key].les_question && lessonData.value[key].les_question.length) {
          // eslint-disable-next-line
          xquestionsData.value[key] = lessonData.value[key].les_question.map(() => {
            return {}
          })
        }

        return res
      }).catch(err => {
        loadingLesson.value = false

        return err
      })
    }

    const loading = ref(false)
    const courseData = ref(null)
    const startIndex = ref(0)
    const fetchCourse = () => {
      loading.value = true
      store.dispatch('app/fetchData2', {
        api: 'https://api.prohealthfactor.ru/api/v1/json/lkb/info/course/item/learn',
        apiId: router.currentRoute.params.id,
      }).then(async res => {
        const { data } = res.data
        courseData.value = data
        loading.value = false

        if (courseData.value.startIndex !== '0') {
          startIndex.value = parseInt(courseData.value.startIndex, 10)
          if (startIndex.value === courseData.value.lessons.length) {
            startIndex.value -= 1
          }

          for (let index = 0; index <= startIndex.value; index++) {
            if (courseData.value.lessons[index]) {
              await fetchLesson(courseData.value.lessons[index], index, startIndex.value)
            }
          }

          loadingLesson.value = false
        } else {
          fetchLesson(courseData.value.lessons[0], 0, 0).then(() => {
            loadingLesson.value = false
          })
        }
      }).catch(() => {
        loading.value = false
      })
    }

    const finishData = ref('')
    // eslint-disable-next-line
    const finishLesson = (id, data) => {
      loadingLesson.value = true
      return store.dispatch('app/sendData', {
        api: 'https://api.prohealthfactor.ru/api/v1/htm/lkb/info/course/page/finishLesson',
        apiId: id,
        data,
      }).then(res => {
        finishData.value = res.data
        loadingLesson.value = false

        return res
      }).catch(err => {
        loadingLesson.value = false
        return err
      })
    }

    const nextTab = p => {
      const lesson = courseData.value.lessons[p.activeTabIndex + 1]
      // eslint-disable-next-line
      let xparam1 = undefined

      if (questionsData.value && questionsData.value[0] && questionsData.value[0].question !== undefined) {
        xparam1 = JSON.stringify(questionsData.value)
      }

      if (xquestionsData.value[p.activeTabIndex] && xquestionsData.value[p.activeTabIndex][0] && xquestionsData.value[p.activeTabIndex][0].question !== undefined) {
        xparam1 = JSON.stringify(xquestionsData.value[p.activeTabIndex])
      }

      finishLesson(courseData.value.lessons[p.activeTabIndex].id, { xparam1 })
        .then(res => {
          if (res.data.success) {
            if (res.data.html) {
              root.$swal({
                icon: 'success',
                html: res.data.html,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                p.nextTab()
                fetchLesson(lesson, p.activeTabIndex + 1, p.activeTabIndex + 1)
                  .then(() => {
                    const rootEle = document.documentElement
                    rootEle.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                    loadingLesson.value = false
                  })
              })
            } else {
              p.nextTab()
              fetchLesson(lesson, p.activeTabIndex + 1, p.activeTabIndex + 1)
                .then(() => {
                  const rootEle = document.documentElement
                  rootEle.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                  loadingLesson.value = false
                })
            }
          } else {
            root.$swal({
              icon: 'info',
              html: res.data.html,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
    }

    const changeAsk = (question, ask) => {
      questionsData.value[question].question = question
      questionsData.value[question].ask = ask

      console.log(questionsData.value)
    }

    const changeXAsk = (key, question, ask) => {
      xquestionsData.value[key][question].question = question
      xquestionsData.value[key][question].ask = ask
    }

    const handleComplete = () => {
      const lesson = courseData.value.lessons[courseData.value.lessons.length - 1]
      // eslint-disable-next-line
      let xparam1 = undefined

      if (lesson.les_type === '3') {
        xparam1 = JSON.stringify(questionsData.value)
      } else {
        xparam1 = JSON.stringify(xquestionsData.value[courseData.value.lessons.length - 1])
      }

      finishLesson(lesson.id, { xparam1 }).then(() => {
        isComplete.value = true
      })
    }

    const handleReady = () => {}

    const handlePlay = () => {}

    fetchCourse()

    return {
      server,
      isComplete,
      loading,
      startIndex,
      courseData,
      loadingLesson,
      lessonData,
      finishData,
      nextTab,
      changeAsk,
      changeXAsk,
      handleComplete,
      handleReady,
      handlePlay,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>

.relative {
  position: relative;
}
.form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0;
    min-height: auto;
  }
  .wizard-navigation .wizard-nav li:not(:first-child) a::before {
    display: none;
  }

  .wizard-icon-circle {
    align-items: center;
  }

  .wizard-icon-circle .wizard-icon-container {
    height: 100%;
    align-items: center;
  }
}
</style>
<style>
  .ask-radio.custom-control {
    padding-left: 0;
  }

  .ask-radio .custom-control-input {
    display: none;
  }

  .ask-radio .custom-control-label {
    cursor: pointer;
    width: 100%;
  }

  .ask-radio .custom-control-label::before {
    display: none;
  }

  .ask-radio .custom-control-label::after {
    display: none;
  }

  .ask-radio .custom-control-label span {
    padding: 1rem;
    display: block;
    border: 1px solid #ebe9f1;
    border-radius: 0.428rem;
    width: 100%;
  }

  .ask-radio .custom-control-input:checked ~ .custom-control-label span {
    border-color: #1D71B8;
  }

  .ask-radio .custom-control-label:hover span {
    border-color: #1d72b875;
  }

  .cource-slide-img {
    max-width: 100%;
  }

  .course-player {
    width: 100%;
    max-width: 780px;
  }

  .wizard-navigation .wizard-nav li a .stepTitle {
    max-width: 280px;
    text-align: left;
  }

  .course-subtitle p {
    margin-bottom: 0;
  }
</style>
